import styled from "styled-components";

export const $ThankYou = styled.div`
  padding: 7em 0;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 1rem;
  }
  div {
    padding: 3em 8em;
    border-radius: 1em;
    border: 2px solid green;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    h1 {
      font-weight: 700;
      padding: 0.3em;
    }
    h3 {
      width: 80%;
      padding: 0 1em;
    }
    .postulationCTA {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: auto;
      text-align: center;
      color: #ffffff;
      font-weight: 600;
      font-size: 1rem;
      background: transparent linear-gradient(105deg, #eb5536 0%, #ff886f 100%)
        0% 0% no-repeat padding-box;
      border-radius: 5em;
      border: none;
      &:hover {
        background: transparent
          linear-gradient(105deg, #eb5536 0%, #eb5536 100%) 0% 0% no-repeat
          padding-box;
      }
      a {
        width: 100%;
        text-decoration: none;
        color: #fff;
        padding: 0.7em 2.5em;
      }
    }
  }
`;
