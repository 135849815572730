import React from "react";
import SEO from "../components/seo";
import BaseLayout from "../components/BaseLayout";
import { $ThankYou } from "../styles/SC_ThankYou";
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";

function ThankYouPage({ pageContext: { fechedOfferList } }) {
  const isBrowser = () => typeof window !== "undefined";
  isBrowser() && window.location.assign("https://www.pfresolu.com/Carrieres/");
  const intl = useIntl();

  return (
    <>
      <BaseLayout>
        <SEO description="Merci" title="Resolu" />

        <$ThankYou>
          <div>
            <h1>{intl.formatMessage({ id: "ThankYouPage.CVRecive" })}</h1>
            <p>{intl.formatMessage({ id: "ThankYouPage.ThanksMessage" })}</p>
          </div>
        </$ThankYou>
      </BaseLayout>
    </>
  );
}

export default ThankYouPage;
